import TableCell from "@mui/material/TableCell";

function HeaderCell({ item }) {
  return (
    <TableCell
      align="center"
      sx={{
        padding: "12px 0px",
        fontSize: "18px",
        color: "white",
      }}
    >
      {item}
    </TableCell>
  );
}

export default HeaderCell;
