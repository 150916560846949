import { useState } from "react";
import Calendar from "react-calendar";

function DatePick({ setDate }) {
  return (
    <div id="header-calendar">
      <Calendar onClickDay={(e) => setDate(e)}></Calendar>
    </div>
  );
}

export default DatePick;
