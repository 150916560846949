import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StandardGridCell from "./Grid Cells/StandardGridCell";
import GrayGridCell from "./Grid Cells/GrayGridCell";
import HeaderCell from "./Grid Cells/HeaderCell";
import ErrorGridCell from "./Grid Cells/ErrorGridCell";
import DataNotFound from "./DataNotFound";
import {useState} from "react";
import { styled } from '@mui/system';
import TablePaginationUnstyled, {
    tablePaginationUnstyledClasses as classes,
} from '@mui/base/TablePaginationUnstyled';
import {blue, grey} from "@mui/material/colors";
import { useAuth0 } from "@auth0/auth0-react";


function ImageGrid({ dataNotFound, headers, objectList, resolutionId, setResolutionId, setOffset, setLimit,
                       loading, setLoading, setHeaders, setObjectList, offset, limit, activeLocation, date, resolutionResults, setResolutionResults, queriedObjectsPage, setQueriedObjectsPage, flag, setFlag, page, setPage}) {

    const [noMatchObjectId, setObjectId] = useState([]);
    const [skipObjectId, setSkipObjectId] = useState([]);
    const [clickedId, setClickedId] = useState([]);
    const [, setResolutionTracker] = useState([{}]);
    //const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [prepageVal, setPrepageVal] = useState(0);

    //const [resolutionResults, setResolutionResults] = useState([]);


    let finalLoad = 0;
    let updatedValue = {};
    let resolutionDict = {};
    console.log(resolutionId);

    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    async function authRequest() {
        return {
            Authorization: `Bearer ${await getAccessTokenSilently({
                username: user.email,
            })}`,
        };
    }

    function handleClick (id,sessionId, resolution,created_Date, e) {

        if(!noMatchObjectId.includes(id) /*&& !skipObjectId.includes(id)*/) {
            if(!(clickedId.includes(id))) {
                setClickedId(clickedId => [...clickedId, id]);
            }
            console.log("1st click logic")
            setObjectId(noMatchObjectId => [...noMatchObjectId, id]);
            const resolution = resolutionId.map(record => {
                if (record.sessionId === sessionId) {
                    return {...record, resolution: 'NO_MATCH'};
                }
                return record;
            });
            console.log(resolution)
            setResolutionId(resolution);
        }
        //Skip logic(Removing now since there is no use case)

        // else if(noMatchObjectId.includes(id) && !skipObjectId.includes(id)){
        //     if(!(clickedId.includes(id))) {
        //         setClickedId(clickedId => [...clickedId, id]);
        //     }
        //     console.log("2nd click logic")
        //     setObjectId(current =>
        //         current.filter(noMatchObjectId => {
        //             return noMatchObjectId !== id
        //         })
        //     )
        //     setSkipObjectId(skipObjectId => [...skipObjectId, id]);
        //     const resolution = resolutionId.map(record => {
        //         if (record.sessionId === sessionId) {
        //             return {...record, resolution: 'SKIP'};
        //             console.log("Reached inside update")
        //         }
        //
        //         return record;
        //     });
        //     setResolutionId(resolution);
        // }

        else if(noMatchObjectId.includes(id)){
            console.log("3rd click logic")
            setObjectId(current =>
                     current.filter(noMatchObjectId => {
                         return noMatchObjectId !== id
                     })
                 )
            const resolution = resolutionId.map(record => {
                if (record.sessionId === sessionId) {
                    return {...record, resolution: 'MATCH'};
                    console.log("Reached inside update")
                }
                return record;
            });
            setResolutionId(resolution);
        }
        console.log(e.detail);
        console.log(id);
    }

    console.log(noMatchObjectId);
    console.log(skipObjectId);
    console.log(resolutionId);

    if (dataNotFound) {
        return (
            <DataNotFound
                key={new Date()}
                message={"No data found. Try another Date."}
            ></DataNotFound>
        );
    }

    const handleChangePage = async (event, newPage) => {

            setQueriedObjectsPage(queriedObjectsPage => [...queriedObjectsPage, newPage])
            console.log(queriedObjectsPage);
            console.log("Reached Imagegrid get objects");
            let offset = newPage
            let limit = rowsPerPage
            //const pageNumber = newPage;
            console.log("offset", offset)
            console.log("newPage", newPage)
            console.log("rowsPerPage", rowsPerPage)
            setPage(newPage);
            // console.log("Limit", limit);
            // if (!queriedObjectsPage.includes(newPage)) {
            //     if (resolutionResults.length === 0) {
            //         const splicedElements = resolutionId.splice(0, limit);
            //         setResolutionId(resolutionId => [...resolutionId, ...splicedElements]);
            //         console.log(splicedElements)
            //         setResolutionResults(splicedElements);
            //     } else {
            //         const splicedElements = resolutionId.splice(resolutionResults.length, limit);
            //         setResolutionId(resolutionId => [...resolutionId, ...splicedElements]);
            //         console.log(splicedElements)
            //         setResolutionResults(resolutionResults => [...resolutionResults, ...splicedElements]);
            //     }
            //     if ((resolutionId.length - resolutionResults.length) < limit) {
            //         const splicedElements = resolutionId.splice(resolutionResults.length, limit);
            //         setResolutionId(resolutionId => [...resolutionId, ...splicedElements]);
            //         console.log(resolutionResults)
            //         console.log(splicedElements)
            //         //setResolutionResults(resolutionResults => [...resolutionResults, ...splicedElements]);
            //         finalLoad = 1;
            //     }
            // }
            //else{
            //     resolutionResults.splice((newPage * limit), limit);
            //     const splicedElements = resolutionId.splice(resolutionResults.length, limit);
            //     console.log(splicedElements)
            //     setResolutionResults(resolutionResults => [...resolutionResults, ...splicedElements]);
            // }

            console.log(resolutionResults);

            if(finalLoad != 1) {
                console.log("calling")
                fetch(
                    `https://akbt98in92.execute-api.us-west-2.amazonaws.com/resolutiontool/-objects?location=${activeLocation}&date=${date}&offset=${offset}&limit=${limit}`,
                    {
                        headers: new Headers(await authRequest()),
                    }
                ).then((response) =>
                    response.json().then(async (res) => {
                        console.log(Object.entries(res[1]).length)
                        // if (prepageVal > newPage) {
                        //     prePageFlag = 1;
                        // }

                        if (flag != 1 && !queriedObjectsPage.includes(newPage)) {
                            setObjectList(objectList => [...objectList, ...Object.entries(res[1])]);
                            setPrepageVal(newPage);
                            console.log(prepageVal);
                        }
                        if (Object.entries(res[1]).length < 300) {
                            flag = 1;
                        }

                        //setObjectList(Object.entries(res[1]));
                    })
                );
            }
    };


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - objectList.length) : 0;

    console.log(emptyRows);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const CustomTablePagination = styled(TablePaginationUnstyled)(
        ({ theme }) => `
          & .${classes.spacer} {
            display: none;
          }
        
          & .${classes.toolbar}  {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        
            @media (min-width: 768px) {
              flex-direction: row;
              align-items: center;
            }
          }
        
          & .${classes.selectLabel} {
            margin: 0;
          }
        
          & .${classes.select}{
            padding: 2px;
            border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
            border-radius: 50px;
            background-color: transparent;
        
            &:hover {
              background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
            }
        
            &:focus {
              outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
            }
          }
        
          & .${classes.displayedRows} {
            margin: 0;
        
            @media (min-width: 768px) {
              margin-left: auto;
            }
          }
        
          & .${classes.actions} {
            padding: 2px;
            border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
            border-radius: 50px;
            text-align: center;
          }
        
          & .${classes.actions} > button {
            margin: 0 8px;
            border: transparent;
            border-radius: 2px;
            background-color: transparent;
        
            &:hover {
              background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
            }
        
            &:focus {
              outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
            }
          }
          `,
    );

    return (

        <div className="content-container" id="image-grid-content">
            {!dataNotFound && objectList.length > 0 && (
                <TableContainer
                    elevation={1}
                    className="object-table-container"
                    sx={{
                        width: "99vw",
                        minWidth: "50vw",
                        marginTop: "7px",
                        borderRadius: "3px",
                        maxHeight: "100vh",
                    }}
                    component={Paper}
                >
                    <Table id="object-table" sx={{ tableLayout: "fixed" }} aria-label="custom pagination table">
                        <thead
                            sx={{
                                position: "sticky",
                                top: "0px",
                                zIndex: "100",
                                transition: "ease",
                            }}
                        >
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[50, 100, 150]}
                                colSpan={3}
                                count={objectList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                componentsProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                        </thead>
                        <TableHead
                            sx={{
                                position: "sticky",
                                top: "0px",
                                zIndex: "100",
                                transition: "ease",
                            }}
                        >
                            <TableRow
                                sx={{ backgroundColor: "#1d3557", whiteSpace: "nowrap" }}
                            >
                                {headers &&
                                    headers.map((item) => {
                                        return <HeaderCell item={item}></HeaderCell>;

                                    })}
                            </TableRow>
                        </TableHead>

                        <TableBody sx={{ padding: "0px", overflowY: "scroll" }}>
                            {(rowsPerPage > 0
                                    ? objectList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : objectList
                            ).map((row) => {
                                return (
                                    <TableRow key={row[0]} sx={{ height: 50 }} >
                                        <TableCell
                                            key={row[0]}
                                            align="center"
                                            sx={{ padding: "4px 6px", margin: 0, height: 80 }}
                                            onClick={(e) => handleClick(row[0],row[1].sessionId,row[1].resolution,row[1].created_date, e)}
                                            style={{
                                                backgroundColor: noMatchObjectId.includes(row[0]) || (row[1].resolution === 'NO_MATCH' && (!clickedId.includes(row[0]))) ? "red" : skipObjectId.includes(row[0]) || (row[1].resolution === 'SKIP' && (!clickedId.includes(row[0]))) ? "yellow" : "green"
                                            }}
                                        >
                                            {row[0]}
                                        </TableCell>
                                        {Object.values(row[1]).map((obj, index) => {
                                            if(index === 0 || index === 1 || index === 2){
                                                return;
                                            }
                                            if (index === 3) {
                                                return (
                                                    <TableCell
                                                        key={obj.key}
                                                        align="center"
                                                        sx={{ padding: "4px 6px", margin: 0, height: 80 }}
                                                    >
                                                        {obj.timestamp}
                                                    </TableCell>
                                                );
                                            }
                                            if (
                                                obj.timestamp === null ||
                                                obj["timestamp"] === undefined
                                            ) {
                                                return <ErrorGridCell errorKey={obj.key} key={obj.key}></ErrorGridCell>;
                                            } else if (obj.timestamp && obj["url"] === undefined) {
                                                return (
                                                    <GrayGridCell
                                                        key={obj.key}
                                                        grayKey={obj.key}
                                                        timestamp={obj.timestamp}
                                                    ></GrayGridCell>
                                                );
                                            }
                                            return (
                                                <StandardGridCell
                                                    key={obj.key}
                                                    standardKey={obj.key}
                                                    url={obj.url}
                                                    timestamp={obj.timestamp}
                                                ></StandardGridCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <tr style={{ height: 34 * emptyRows }}>
                                    <td colSpan={3} />
                                </tr>
                            )}
                        </TableBody>
                        <tfoot>
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[50, 100, 150]}
                                colSpan={3}
                                count={objectList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                componentsProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                        </tfoot>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
export default ImageGrid;


