import { useState } from "react";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Form } from "semantic-ui-react";
function LocationList({ locations, activeLocation, setActiveLocation }) {
  const options = locations.map((location) => {
    return { value: location, label: location };
  });

  return (
    <FormControl
      fullWidth={true}
      sx={{
        width: "30%",
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "#1d3557",
          },
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": {
            borderColor: "#1d3557",
          },
        },
      }}
    >
      <Select
        value={activeLocation}
        onChange={(e) => setActiveLocation(e.target.value)}
        id="location-select"
      >
        {locations.map((location) => {
          return (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export default LocationList;
