import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";

function ErrorGridCell() {
  return (
    <TableCell
      align="center"
      className="image-and-time-container"
      sx={{
        backgroundColor: "#fafafa",
        padding: "4px 4px",
      }}
      component={Paper}
    >
      <Paper
        sx={{
          margin: "auto",
          height: "100%",
          width: "98%",
          backgroundColor: "#e63946",
        }}
      ></Paper>
    </TableCell>
  );
}

export default ErrorGridCell;
