import DatePick from "./DatePick";
import Button from "@mui/material/Button";
import LocationList from "./LocationList";
import React, { useState, useEffect, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import dataNotFound from "../Grid/DataNotFound";
import ImageGrid from "../Grid/ImageGrid";
import swal from 'sweetalert';

function Header({setDataNotFound, setHeaders,
  headers,
  objectList,
  setObjectList,
  resolutionId, setResolutionId, setLoading, loading, activeLocation, setActiveLocation, date, setDate, offset, limit, resolutionResults, setResolutionResults, queriedObjectsPage, setQueriedObjectsPage, flag, setFlag, setPage
}) {

  const [locations, setLocations] = useState([]);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  let objectLength = objectList.length;
  let resolutionLength = resolutionId.length;

    console.log(resolutionResults);
    console.log(resolutionId);
  let scrollTo = (id) => {
    document.getElementById(id).scrollIntoView();
  };
  async function authRequest() {
    return {
      Authorization: `Bearer ${await getAccessTokenSilently({
        username: user.email,
      })}`,
    };
  }

    const getObjects = async () => {
        setLoading(true);
        console.log("Reached header get objects")
        fetch(
            `https://akbt98in92.execute-api.us-west-2.amazonaws.com/resolutiontool/-objects?location=${activeLocation}&date=${date}&offset=${offset}&limit=${limit}`,
            {
                headers: new Headers(await authRequest()),
            }
        ).then((response) =>
            response.json().then(async (res) => {
                console.log(await authRequest());
                console.log("res", res);
                if (res[0].length > 0 && Object.keys(res[1]).length > 0 && res[1]) {
                    console.log(Object.keys(res[1]));
                    setHeaders(["Events", ...res[0]]);
                } else {
                    setHeaders([]);
                }
                setObjectList(Object.entries(res[1]));
            })
        );
        setQueriedObjectsPage([]);
        console.log(queriedObjectsPage);
        setQueriedObjectsPage([0]);
        console.log(queriedObjectsPage);
        setFlag(0);
        setPage(0);
        setResolutionId([]);
    };

    const postResolution = async () => {
        // setResolutionId([]);
        // setResolutionId(resolutionResults);
        console.log(resolutionId);
      fetch(
          `https://akbt98in92.execute-api.us-west-2.amazonaws.com/resolutiontool/qa_resolutions`,
          {
              method: "POST",
              headers: new Headers(await authRequest()),
              body: JSON.stringify({
                  resource: '/-resolutions',
                  body: JSON.stringify(resolutionId),
                  username: user.email,
                  locationValue: activeLocation,
                  dateValue: date
              }),
          }
      ).then((response) =>
          response.json().then(async (res) => {
              console.log("res", res);
              if(res.body.body[3] === 'true') {
                  swal({
                      title: "Resolution saved successfully!",
                      text: res.body.body[1] + " records got " + res.body.body[0] + ", saved  by " + res.body.body[2],
                      icon: "success",
                      button: "OK!"
                  });
              }else {
                  swal({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Failed to save to db while' + res.body.body[0] + 'ing !',
                  })
              }

          })
      );
      //setObjectList([]);
      setResolutionId([]);
      await getObjects();
  };

  useEffect(() => {
    if (headers && headers.length > 0) {
      setDataNotFound(false);
    } else if (headers !== null) {
      setDataNotFound(true);
    }
  }, [headers]);

  useEffect(() => {
    if (objectList) {
      setLoading(false);
    }
  }, [objectList]);

    useEffect(() => {
        if(!(objectLength === resolutionLength)) {
            console.log(objectLength);
            console.log(resolutionLength);
            if(resolutionLength === 0) {
                const resolution = objectList.map((record) => ({
                    sessionId: record[1].sessionId,
                    resolution: (record[1].resolution !== null && record[1].resolution !== 'ASSIGNED') ? record[1].resolution : 'MATCH',
                    createdAt: record[1].created_date
                }));
                console.log(resolution)
                setResolutionId(resolution);
            }else{
                const splicedElements = objectList.splice(0, resolutionLength)
                console.log(objectList)
                console.log(splicedElements)
                const resolution = objectList.map((record) => ({
                    sessionId: record[1].sessionId,
                    resolution: (record[1].resolution !== null && record[1].resolution !== 'ASSIGNED') ? record[1].resolution : 'MATCH',
                    createdAt: record[1].created_date
                }));
                let array = objectList;
                console.log(array);
                setObjectList([]);
                array = splicedElements.concat(array);
                setObjectList(array);
                //setObjectList(objectList => [...objectList, ...splicedElements])

                console.log(resolution)
                //setResolutionId(resolution);
                setResolutionId(resolutionId => [...resolutionId, ...resolution])
            }
        }

    }, [objectList, objectLength, resolutionLength]);

  useEffect(() => {
    if (!loading && objectList.length > 0) {
      scrollTo("image-grid-content");
    }
  }, [loading]);
  useEffect(() => {
    async function fetchData() {
      // You can await here
      console.log(await authRequest());
      fetch(
        "https://akbt98in92.execute-api.us-west-2.amazonaws.com/resolutiontool/-locations",
        {
          headers: new Headers(await authRequest()),
        }
      ).then((response) =>
        response.json().then((data) => {
          setLocations(data);
        })
      );
    }
    fetchData();
  }, []);

  return (
    <header className="header-container">
      <DatePick setDate={setDate}></DatePick>
      <LocationList
        locations={locations}
        activeLocation={activeLocation}
        setActiveLocation={setActiveLocation}
      ></LocationList>
      <Button
        disabled={activeLocation === "" || loading}
        onClick= {getObjects}
        id="show-grid-button"
      >
        Show Grid
      </Button>

      <Button
          variant="contained"
          disabled={activeLocation === "" || !(resolutionId.length) || loading}
          onClick={
              postResolution}
          id="submit"

      >
        Submit
      </Button>

      {loading ? (
        <CircularProgress id="loading-wheel"></CircularProgress>
      ) : null}


    </header>
  );
}

export default Header;