import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function DataNotFound({ message }) {
  return (
    <div className="fade-in empty-data-message-container">
      <div className="empty-data-message">
        <ErrorOutlineIcon id="no-grid-data-icon" />
        <p>{message}</p>
      </div>
    </div>
  );
}

export default DataNotFound;
