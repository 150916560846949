import Header from "./Header/Header";
import ImageGrid from "./Grid/ImageGrid";
import {useState} from "react";

function Home() {
    const [objectList, setObjectList] = useState([]);
    const [headers, setHeaders] = useState(null);
    const [dataNotFound, setDataNotFound] = useState(false);
    const [resolutionId, setResolutionId] = useState([]);
    const [noMatchId, setNoMatchId] = useState([]);
    const [skipId, setSkipId] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(100);
    const [loading, setLoading] = useState(false);
    const [activeLocation, setActiveLocation] = useState("");
    const [date, setDate] = useState(new Date());
    const [resolutionResults, setResolutionResults] = useState([]);
    const [queriedObjectsPage, setQueriedObjectsPage] = useState([0]);
    const [flag, setFlag] = useState(0);
    const [page, setPage] = useState(0);

    console.log(offset);
    console.log(objectList);
    console.log(resolutionId);
    return (
      <>
        <Header
          setDataNotFound={setDataNotFound}
          setHeaders={setHeaders}
          headers={headers}
          objectList={objectList}
          setObjectList={setObjectList}
          resolutionId = {resolutionId}
          setResolutionId = {setResolutionId}
          setLoading = {setLoading}
          loading = {loading}
          activeLocation = {activeLocation}
          setActiveLocation = {setActiveLocation}
          date = {date}
          setDate = {setDate}
          offset = {offset}
          limit = {limit}
          resolutionResults = {resolutionResults}
          setResolutionResults = {setResolutionResults}
          queriedObjectsPage = {queriedObjectsPage}
          setQueriedObjectsPage = {setQueriedObjectsPage}
          flag = {flag}
          setFlag ={setFlag}
          setPage = {setPage}
        ></Header>
        <ImageGrid
          dataNotFound={dataNotFound}
          headers={headers}
          objectList={objectList}
          resolutionId = {resolutionId}
          setResolutionId = {setResolutionId}
          setOffset = {setOffset}
          setLimit = {setLimit}
          loading = {loading}
          setLoading = {setLoading}
          setHeaders={setHeaders}
          setObjectList={setObjectList}
          offset = {offset}
          limit = {limit}
          activeLocation = {activeLocation}
          date = {date}
          resolutionResults = {resolutionResults}
          setResolutionResults = {setResolutionResults}
          queriedObjectsPage = {queriedObjectsPage}
          setQueriedObjectsPage = {setQueriedObjectsPage}
          flag = {flag}
          setFlag = {setFlag}
          page ={page}
          setPage = {setPage}
        ></ImageGrid>

      </>
    );
}

export default Home
