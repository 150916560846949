import { useState } from "react";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GrayGridCell from "./GrayGridCell";
import Popover from "@mui/material/Popover";
function StandardGridCell({ url, timestamp }) {
  const [imgError, setImgError] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  let handleImgError = () => {
    setImgError(true);
  };
  let handlePopoverOpen = (e) => {
    setAnchorElement(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  if (imgError) {
    return <GrayGridCell timestamp={timestamp}></GrayGridCell>;
  }
  const open = Boolean(anchorElement);
  return (
    <TableCell
      align="center"
      component={Paper}
      sx={{
        backgroundColor: "#fafafa",
        padding: "4px 4px",
      }}
    >
      <Paper className="image-and-time-container">
        <img
          className="standard-img"
          onClick={(e) => handlePopoverOpen(e)}
          alt="car object"
          src={url}
          onError={handleImgError}
        />
        <Popover
          open={open}
          onClose={handlePopoverClose}
          anchorEl={anchorElement}
          elevation={14}
        >
          <div
            style={{
              borderRadius: "3px",
              width: "700px",
              height: "400px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="car object"
              src={url}
              style={{ width: "95%", height: "90%", borderRadius: "1px" }}
            />
          </div>
        </Popover>
        <div className="grid-cell-timestamp">
          <AccessTimeIcon style={{ fontSize: "18px" }}></AccessTimeIcon>
          {timestamp}
        </div>
      </Paper>
    </TableCell>
  );
}

export default StandardGridCell;
