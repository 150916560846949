import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function GrayGridCell({ timestamp }) {
  return (
    <TableCell
      align="center"
      sx={{
        padding: "4px 7px",
        fontSize: "14px",
        height: 50,
      }}
      component={Paper}
      className="timestamp-only-cell"
    >
      <Paper
        sx={{
          color: "white",
          backgroundColor: "#4a4a4a",
          opacity: "85%",
          border: "none",
          display: "flex",
          flexDirection: "row",
          width: "98%",
          height: 140,
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "underline",
        }}
        className="image-and-time-container"
      >
        <AccessTimeIcon
          className="time-icon"
          sx={{ width: "18px", marginRight: "3px" }}
        />
        <div>{timestamp}</div>
      </Paper>
    </TableCell>
  );
}
export default GrayGridCell;
